//[


//   {
//     title: 'Products',
//     route: 'apps-products-list',
//     icon: 'GridIcon'
//   },
//   {
//     title: 'Orders',
//     route: 'apps-orders-list',
//     icon: 'ShoppingCartIcon'
//   },

//    {
//     title: 'Services',
//     route: 'apps-services',
//     icon: 'LayersIcon'
//   },
//   {
//     title: 'Bookings',
//     route: 'apps-bookings',
//     icon: 'BookmarkIcon'
//   },
//   {
//     title: 'Messages',
//     route: 'apps-chat',
//     icon: 'MessageSquareIcon',
//   },
// ]
//E:\data\VueProjects\brand-away-frontend\src\store\index.js
//import store from '../store/index.js';
//console.log(this.$store.state.userData.type); 

//store.getters.config
console.log("this is from locsl",localStorage.getItem('myuserType') );
var role =  localStorage.getItem('myuserType');
var show = [];

function MyFunc()
{
  if(role == "Services")
  {
   return show = myarr2;
  }
  else if(role == "Both" )
  {
    return show = fullarr
  }
  else
  {
    return show = myarr;
  }
}

let myarr = [
  {
    title: 'Products',
    route: 'apps-products-list',
    icon: 'GridIcon'
  },
  {
    title: 'Orders',
    route: 'apps-orders-list',
    icon: 'ShoppingCartIcon'
  },
  {
    title: 'Membership',
    route: 'apps-membership',
    icon: 'UserCheckIcon'
  },
  {
    title: 'Manage Address',
    route: 'apps-address',
    icon: 'MapPinIcon'
  },
]

let myarr2 = [
  

   {
    title: 'Services',
    route: 'apps-services',
    icon: 'LayersIcon'
  },
  {
    title: 'Bookings',
    route: 'apps-bookings',
    icon: 'BookmarkIcon'
  },
  {
    title: 'Membership',
    route: 'apps-membership',
    icon: 'UserCheckIcon'
  },
  {
    title: 'Manage Address',
    route: 'apps-address',
    icon: 'MapPinIcon'
  },
  
]

let fullarr = [
  
  {
    title: 'Products',
    route: 'apps-products-list',
    icon: 'GridIcon'
  },
  {
    title: 'Orders',
    route: 'apps-orders-list',
    icon: 'ShoppingCartIcon'
  },

  {
   title: 'Services',
   route: 'apps-services',
   icon: 'LayersIcon'
 },
 {
   title: 'Bookings',
   route: 'apps-bookings',
   icon: 'BookmarkIcon'
 },
 {
  title: 'Membership',
  route: 'apps-membership',
  icon: 'UserCheckIcon'
},
{
  title: 'Manage Address',
  route: 'apps-address',
  icon: 'MapPinIcon'
},
]
export default  MyFunc() 
